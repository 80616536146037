.service {
    padding-top: 60px;
	
	.row {
		margin-top: 40px;
	}
	
	.data-access-governance {
		section {
			text-align: justify;
		}
	}
}


.popup-videos {
    position: relative;
    max-width: 470px;

    .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: none;
    }
}

.service {
    /*padding: 30px 50px 0 50px;*/

    .section-title {
        margin-bottom: 20px;
		width: 100%;
		padding-left: 15px;
    }

    p {
        font-size: 18px;
        line-height: 34px;
    }
	
	img {
		&.small {
			max-width: 30%;
			margin-right: 50px;
			margin-bottom: 20px;
			float: left;
			
			@media (max-width: 991px) {
				max-width: 40%;
				margin-right: 30px;
				margin-bottom: 10px;
			}
			
			@media (max-width: 730px) {
				max-width: 50%;
			}
			
			@media (max-width: 450px) {
				max-width: 100%;
			}
		}
		
		&.right {
			float: right;
			margin-right: 0px;
			margin-left: 50px;
			
			@media (max-width: 991px) {
				margin-left: 30px;
			}
		}
	}
}

.featured-two-service {
    padding: 145px 0 28px;
}

@media (max-width: 991px) {
    .service {
        padding-top: 80px;
    }

    .service-content {
        padding: 0;
        margin-top: 50px;
    }
}
