.turing-test {
	/* hide the first name and last name, because they are used as turing test and are to be left empty */
	/* not used right now, tabindex can lead to a value, and sometimes the fields appeared in the trial request license form
	input:nth-child(2) {
		position: absolute;
		width: 25%;
	}
	input:nth-child(3) {
		position: absolute;
		width: 25%;
	}
	input:nth-child(4) {
		position: relative;
	}*/
	
	label.logo {
		width: 40px;
		height: 40px;
		background-color: #f8fbf9;
		border: 1px solid #a5d05e;
		border-radius: 10px;
		display: inline-block;
		margin-left: 20px;
		cursor: pointer;
		
		&.checked {
			background-color: #a5d05e;
			border: 10px solid #f8fbf9;
		}
	}
	
	label.robot {
		margin-left: 20px;
		margin-top: 7px;
	}
}

input,
textarea,
select {
    background: #f8fbf9;
    padding: 15px 30px;
    border: 1px solid #a5d05e;
    margin-bottom: 30px;
    border-radius: 30px;

    &:focus {
        background: #fff;
        box-shadow: 0px 20px 40px 0px rgba(38, 79, 35, 0.1);
    }
}

textarea {
    height: 200px;
}


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;  
    // background: #2c3e50;
    background-image: none;
}

/* Remove IE arrow */
select::-ms-expand {
    display: none;
}

/* Custom Select */
.pix-select {
    position: relative;
    display: flex; 
}

select {
    flex: 1;
    // padding: 0 .5em;
    color: #000;
    cursor: pointer;
}

/* Arrow */
.pix-select:after {
    content: "\33";
    font-family: eleganticons;
    position: absolute;
    top: 19%;
    right: 0;
    padding-right: 15px;
    cursor: pointer;
    pointer-events: none;
    transition: .25s all ease;
    font-size: 24px;
}

/* Transition */
.pix-select:hover:after {
    color: $color__theme;
}