.page-banner {
    background: $background__bg;
    text-align: center;
    height: 330px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    .page-title {
        font-size: 50px;
        font-weight: 800;
        color: $color__primary;
        position: relative;
        z-index: 2;
        max-width: 820px;
        margin: 100px auto 15px;
		
		.page-icon-wrapper {
			display: inline-block;
			
			img {
				height: 60px;
				margin-right: 30px;
				margin-top: -11px;
			}
		}		
    }

    .bradcurmed {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        z-index: 2;

        li {
            display: inline-block;
            position: relative;
            font-size: 18px;

            &:not(:last-child) {
                margin-right: 30px;

                &:before {
                    content: "\35";
                    font-family: eleganticons;
                    position: absolute;
                    right: -25px;
                    top: 0;
                }
            }

            a {
                color: #2b2350;
                font-weight: 500;

                &:hover {
                    color: $color__theme;
                }
            }
        }
    }

    .circle {
        position: absolute;
        top: -51%;
        left: -15%;
        z-index: 1;
    }

    &.blog-details-banner {
        height: 700px;

        .circle {
            position: absolute;
            top: -39%;
            left: -19%;
            z-index: 1;
        }

        .page-title-wrapper {
            margin-top: 60px;
        }

        .page-title {
            font-size: 40px;
            line-height: 54px;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 20px;
        }

        .post-meta {
            position: relative;
            z-index: 22;
            padding: 0;

            li {
                display: inline-block;
                margin-right: 30px;
                position: relative;
                text-transform: uppercase;

                &:not(:last-child):after {
                    position: absolute;
                    content: '';
                    right: -20px;
                    top: 7px;
                    width: 2px;
                    height: 14px;
                    background: #9997a3;
                }

                a {
                    color: #797687;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-left: 5px;

                    &:hover {
                        color: $color__theme;

                    }
                }
            }

            &.color-theme {
                margin-bottom: 10px;

                li {
                    a {
                        color: $color__theme;
                    }
                }

            }
        }
    }
}

.animate-ball {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 0;
    left: 0;

    .ball {
        background: #edf4e2;
        border-radius: 50%;
        position: absolute;

        &:nth-child(1) {
            height: 50px;
            width: 50px;
            top: 37%;
            left: 10%;
            animation: zoommd 1.5s infinite alternate;
        }

        &:nth-child(2) {
            height: 70px;
            width: 70px;
            bottom: 22%;
            right: 8%;
            animation: bounce 4s infinite alternate;
        }

        &:nth-child(3) {
            height: 100px;
            width: 100px;
            bottom: 43%;
            right: 22%;
            animation: zoommd 1.5s infinite alternate;
        }

        &:nth-child(4) {
            height: 40px;
            width: 40px;
            bottom: 20%;
            right: 32%;
            animation: movexy 1.5s infinite alternate;
        }


        &:nth-child(5) {
            height: 500px;
            width: 500px;
            top: -75%;
            right: 100px;
            animation: wave 3s 0.1s infinite linear;
        }
    }
}

.page-banner-contact {
    height: 690px;
    position: relative;
    overflow: hidden;
    background: $background__bg;

    .circle {
        position: absolute;
        top: -40%;
        left: -15%;
    }
}

.page-title-inner {
    position: relative;
    z-index: 2;
}

.animate-element-contact {
    position: relative;
    z-index: 222;
    height: 465px;
    width: 408px;
    bottom: -135px;

    img {
        position: absolute;
        bottom: 0;

        &:nth-child(1) {
            left: 5px;
            bottom: 55px;
        }

        &:nth-child(2) {
            bottom: 41px;
            left: 128px;
        }

        &:nth-child(3) {
            right: 0;
        }

        &:nth-child(4) {
            bottom: 0;
            left: 110px;
        }
    }


}

.page-banner {
    background: $background__bg;
    text-align: center;
    height: 330px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    .page-title {
        font-size: 50px;
        font-weight: 800;
        color: $color__primary;
        position: relative;
        z-index: 2;
        max-width: 820px;
        margin: 100px auto 15px;
    }

    .bradcurmed {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        z-index: 2;

        li {
            display: inline-block;
            position: relative;
            font-size: 18px;

            &:not(:last-child) {
                margin-right: 30px;

                &:before {
                    content: "\35";
                    font-family: eleganticons;
                    position: absolute;
                    right: -25px;
                    top: 0;
                }
            }

            a {
                color: #2b2350;
                font-weight: 500;

                &:hover {
                    color: $color__theme;
                }
            }
        }
    }

    .circle {
        position: absolute;
        top: -51%;
        left: -15%;
        z-index: 1;
    }

    &.blog-details-banner {
        height: 700px;

        .circle {
            position: absolute;
            top: -39%;
            left: -19%;
            z-index: 1;
        }

        .page-title-wrapper {
            margin-top: 60px;
        }

        .page-title {
            font-size: 40px;
            line-height: 54px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .post-meta {
            position: relative;
            z-index: 22;
            padding: 0;

            li {
                display: inline-block;
                margin-right: 30px;
                position: relative;
                text-transform: uppercase;

                &:not(:last-child):after {
                    position: absolute;
                    content: '';
                    right: -20px;
                    top: 7px;
                    width: 2px;
                    height: 14px;
                    background: #9997a3;
                }

                a {
                    color: #797687;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-left: 5px;

                    &:hover {
                        color: $color__theme;

                    }
                }
            }

            &.color-theme {
                margin-bottom: 10px;

                li {
                    a {
                        color: $color__theme;
                    }
                }

            }
        }
    }
}

.animate-ball {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 0;
    left: 0;

    .ball {
        background: #edf4e2;
        border-radius: 50%;
        position: absolute;

        &:nth-child(1) {
            height: 50px;
            width: 50px;
            top: 37%;
            left: 10%;
            animation: zoommd 1.5s infinite alternate;
        }

        &:nth-child(2) {
            height: 70px;
            width: 70px;
            bottom: 22%;
            right: 8%;
            animation: bounce 4s infinite alternate;
        }

        &:nth-child(3) {
            height: 100px;
            width: 100px;
            bottom: 43%;
            right: 22%;
            animation: zoommd 1.5s infinite alternate;
        }

        &:nth-child(4) {
            height: 40px;
            width: 40px;
            bottom: 20%;
            right: 32%;
            animation: movexy 1.5s infinite alternate;
        }


        &:nth-child(5) {
            height: 500px;
            width: 500px;
            top: -75%;
            right: 100px;
            animation: wave 3s 0.1s infinite linear;
        }
    }
}

.page-banner-contact {
    height: 700px;
    position: relative;
    overflow: hidden;
    background: $background__bg;
    z-index: 2;

    .circle {
        position: absolute;
        top: -40%;
        left: -17%;
    }

    .page-title-wrapper {
        padding: 315px 0 245px;
        position: relative;

        .page-title {
            font-size: 40px;
            line-height: 54px;
        }

        p {
            font-size: 16px;
            line-height: 30px;
        }
    }

    .animate-ball {
        .ball {
            &:nth-child(3) {
                bottom: 55%;
                right: 40%;
                height: 70px;
                width: 70px;
            }

            &:nth-child(4) {
                bottom: 28%;
                right: 40%;
            }

            &:nth-child(5) {
                top: -60%;
            }
        }
    }
}

.animate-element-contact {
    position: relative;
    z-index: 222;
    height: 465px;
    width: 408px;
    bottom: -135px;

    img {
        position: absolute;
        bottom: 0;

        &:nth-child(1) {
            left: 5px;
            bottom: 55px;
        }

        &:nth-child(2) {
            bottom: 41px;
            left: 128px;
        }

        &:nth-child(3) {
            right: 0;
        }

        &:nth-child(4) {
            bottom: 0;
            left: 110px;
        }
    }
}

@media (max-width: 1280px) {

    .animate-element-contact {
        margin-left: -80px;
    }
}


@media (max-width: 1200px) {
    .page-banner {
        height: 400px;

        .circle {
            top: -110%;
            left: -35%;
        }
    }

    .animate-ball {
        .ball {
            &:nth-child(5) {
                top: -102%;
                right: 0;
            }
        }
    }

    .animate-element-contact {
        margin-left: -80px;
    }
}

@media (max-width: 1024px) {
    .circle {
        display: none;
    }
}
@media (max-width: 991px) {

    .page-banner {
        height: 300px;

        .page-banner .circle {
            top: -185%;
            left: -45%;
        }

        .page-title {
            font-size: 40px;
            margin: 40px auto 15px;
        }

        .animate-ball {
            .ball {
                &:nth-child(3) {
                    height: 50px;
                    width: 50px;
                }

                &:nth-child(3) {
                    height: 40px;
                    width: 40px;
                }

                &:nth-child(5) {
                    top: -190px;
                    right: 0;
                    height: 250px;
                    width: 250px;
                }

            }
        }

        &.blog-details-banner {
            height: 400px;
            .page-title {
                font-size: 30px;
                line-height: 44px;
            }

            .circle {
                left: -50%;
            }
        }

    }

    .page-banner-contact {
        height: 800px;
    }

    .page-banner-contact {
        .page-title-wrapper {
            padding: 130px 0 0;
        }
    }

    .animate-element-contact {
        height: 365px;
        margin: 0 auto;
    }

    .page-banner {
        .circle {
        left: -60%;
        }
    }
    
}

@media (max-width: 768px) {

    .page-banner {
        .page-title {
            font-size: 30px;
            margin: 40px auto 10px;
			
			.page-icon-wrapper {
				img {
					height: 45px;
					margin-right: 25px;
				}
			}
        }

        
    &.blog-details-banner {
        height: 400px;
        .page-title {
            font-size: 26px;
            line-height: 36px;
        }

        .circle {
            left: -70%;
        }
    }
    }

    .page-banner-contact {
        .page-title-wrapper {
            padding: 100px 0 0;
            
            .page-title {
                font-size: 32px;
            }
            p {
                br {
                    display: none;
                }
            }
        }
    }
    .page-banner {
        .circle {
        left: -80%;
        }
    }

}

@media (max-width: 576px) {
    .page-banner {
        .circle {
        left: -120%;
        }
		
		.page-icon-wrapper {
			width: 100%;
		}
    }
}